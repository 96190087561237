// Components
import BundleDocumentViewer from "../../components/BundleViewer/BundleDocumentViewer";
import PapyrusPage from "../../components/PapyrusPage/PapyrusPage";
// Client 
import Client from "fhir-kit-client";
// FHIR
import { Bundle } from "fhir/r4";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
// React Bootstrap
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";
// Router
import { useNavigate, useParams } from "react-router-dom";
// Services
import CDSService, { Card } from "../../services/CDSService";
import DocumentService, { Document } from "../../services/DocumentService";
// Styles
import styles from "./DocumentImport.module.css";
import { usePDF } from 'react-to-pdf';
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faUpload
  } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const DocumentImport: FunctionComponent = () => {

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////

    const { documentId } = useParams();
    const [data, setData] = useState<unknown | Document>(null);
    const [dataBundle, setDataBundle] = useState<unknown | Bundle>(null);
    const [subject, setSubject] = useState<string>("");
    const [cards, setCards] = useState<Map<string, Card>>(new Map<string, Card>());
    const [isOpen, setIsOpen] = useState<boolean[]>([]);
    const [loading, setLoading] = useState(false);
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    /////////////////////////////////////
    //             Client              //
    ////////////////////////////////////

    const fhirClient = useMemo(() => new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    }), []);

    /////////////////////////////////////
    //           Navigation            //
    /////////////////////////////////////

    const navigate = useNavigate();

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /////////////////////////////////////
    //          Page Loading           //
    /////////////////////////////////////

    /**
     * Load the page
     * 
     */
    useEffect(() => {
        loadPage();
    }, []);

    /**
    * Load the initial state of the page.
    */
    async function loadPage() {
        setLoading(true);
        if (documentId) {
            await loadDocument()
        }
        setLoading(false);
    };

    /////////////////////////////////////
    //             Functions           //
    /////////////////////////////////////

    /**
     * Get the QR code information 
     * 
     * @param text 
     * 
     */
    async function getQRInfo(text: string) {
        if (text) {
            try {
                const response = await fetch(text, { mode: 'no-cors', method: 'GET', headers: { 'Access-Control-Allow-Origin': '*' } });
                const bundle = await response.json();
                const document = await DocumentService.generateDoc(bundle);
                setData(document);
                setDataBundle(bundle);
                setSubject(DocumentService.getComposition(bundle.entry ?? [])?.subject?.reference ?? "");
            } catch (error) {
                onError();
            }
        }
    };

    /**
     * Load the document
     * 
     */
    async function loadDocument() {
        try {
            let bundle: Bundle;
            if (!dataBundle) {
                const response = await fhirClient.read({
                    resourceType: "Bundle",
                    id: documentId ?? "",
                });
                bundle = response as Bundle;
                setDataBundle(bundle);
            } else {
                bundle = dataBundle as Bundle;
            }
            const document = await DocumentService.generateDoc(bundle);
            setData(document);
            setSubject(DocumentService.getComposition(bundle.entry ?? [])?.subject?.reference ?? "");
        } catch (error) {
            onError();
        }
    };

    /**
     * Get QR code information 
     * 
     * @param i 
     */
    function toggle(i: number) {
        setIsOpen({ ...isOpen, [i]: !isOpen[i] });
    };

    /**
     * Handle the click
     * 
     */
    async function handleClick() {
        const newCards = await CDSService.callCDS("ImmunoAtRisk", subject, dataBundle as Bundle);
        setCards(newCards);
        const newIsOpen = Array.from(newCards.keys()).map(() => true);
        setIsOpen(newIsOpen);
    };

    async function checkEligibility() {
        const newCards = await CDSService.callCDS("Eligibility", subject, dataBundle as Bundle);
        setCards(newCards);
        const newIsOpen = Array.from(newCards.keys()).map(() => true);
        setIsOpen(newIsOpen);
    };

    const { toPDF, targetRef } = usePDF({filename: 'document.pdf'});

    const downloadfile = (fr? : boolean) => {
        var type = 'application/fhir+json';
        var extension = '.json';
          const csvData = new Blob([JSON.stringify(dataBundle)], { type: type });
          const csvURL = URL.createObjectURL(csvData);
          const link = document.createElement('a');
          link.href = csvURL;
          if(fr) {
          link.download = "IPS.json.txt";
          }
          else {
            link.download = "IPS.json";
          }
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

      };

      async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            let base64String = '';
            if (file) {
                const raw = await file.text();
                try {
                    const bundle = JSON.parse(raw);
                    const document = await DocumentService.generateDoc(bundle);
                setData(document);
                setDataBundle(bundle);
                setSubject(DocumentService.getComposition(bundle.entry ?? [])?.subject?.reference ?? "");
                } catch {}
            }
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
      }

    /////////////////////////////////////
    //              Content            //
    /////////////////////////////////////

    return (
        <>
            <ToastContainer position='top-end'>
                {Array.from(cards.keys()).map((cardId, i) => (
                    <Toast show={isOpen[i]} onClose={() => toggle(i)}>
                        <Toast.Header>
                            <strong className="me-auto">{cards.get(cardId)?.indicator}</strong>
                        </Toast.Header>
                        <Toast.Body><strong className="me-auto">{cards.get(cardId)?.summary}</strong>
                            <br></br>
                            <small className="text-muted">{cards.get(cardId)?.source}</small>
                        </Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
            <PapyrusPage titleKey='title.bundledocument' loading={loading} fitFooter={false} needsLogin={false}>
                <>
                    {(!data && !documentId) && (<Form>
                        <Form.Group
                            className="mb-3"
                        >
                            <Form.Label className="label">
                                {i18n.t('label.downloadfile') + ' :'
                                }
                            </Form.Label>
                            <Form.Control
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                        </Form.Group>

                    </Form>)}
                    {((data as Document) && (dataBundle as Bundle)) && (<>
                        <Button
                            variant="primary"
                            className={styles.riskButton}
                            onClick={handleClick}
                        >
                            {i18n.t("button.checkingpatientrisk")}
                        </Button>
                        
                            <Button
                            variant="primary"
                            className={styles.riskButton}
                            onClick={checkEligibility}
                        >
                           Check Eligibility
                        </Button>
                        <Button variant="warning"
                            className={styles.riskButton} onClick={() => toPDF()}><FontAwesomeIcon
                            icon={faDownload}
                          />{i18n.t("label.downloadpdf")}</Button>
                            <Button variant="warning"
                            className={styles.riskButton} onClick={() => downloadfile()}><FontAwesomeIcon
                            icon={faDownload}
                          />{i18n.t("label.downloadRawData")}</Button>
                            <Button variant="warning"
                            className={styles.riskButton} onClick={() => downloadfile(true)}><FontAwesomeIcon
                            icon={faDownload}
                          />{i18n.t("label.downloadRawDataFR")}</Button>
                            {(!documentId) && (<Button variant="warning"
                            className={styles.riskButton}><FontAwesomeIcon
                            icon={faUpload}
                          />{i18n.t("label.extractData")}</Button>)}
                        <div ref={targetRef}>
                        <BundleDocumentViewer document={data as Document}></BundleDocumentViewer>
                        </div>
                    </>
                    )}
                </>
            </PapyrusPage>
        </>
    );
};

export default DocumentImport;