// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InProgress_imageIcon__oyGlC {
    width: 25%;
}

.InProgress_mainErrorContainer__0dnB6 {
    height: 90%;
}`, "",{"version":3,"sources":["webpack://./src/pages/InProgress/InProgress.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf","sourcesContent":[".imageIcon {\r\n    width: 25%;\r\n}\r\n\r\n.mainErrorContainer {\r\n    height: 90%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageIcon": `InProgress_imageIcon__oyGlC`,
	"mainErrorContainer": `InProgress_mainErrorContainer__0dnB6`
};
export default ___CSS_LOADER_EXPORT___;
