// FHIR
import { Bundle, BundleEntry, Composition, Patient, FhirResource } from "fhir/r4";
// Terminology
import TerminologyService from "./TerminologyService";
// Translation
import i18n from "i18next";

/////////////////////////////////////
//             Types               //
/////////////////////////////////////

export interface Resource {
  id: string | undefined;
  type: any;
  resource: any;
}

export interface Section {
  code: string | undefined;
  display: any;
  resources: Resource[];
}

export interface Document {
  subject?: string | undefined;
  type?: string;
  author?: string;
  sections?: Section[];
  bundleUrl?: string;
}

/////////////////////////////////////
//             Service             //
/////////////////////////////////////

const terminologyService = new TerminologyService();

/**
 * Generate a document from a bundle
 * 
 * @param bundle Bundle
 * 
 */
async function generateDoc(bundle: Bundle): Promise<Document> {
  let document: Document = {};
  if (bundle.entry) {
    let composition = getComposition(bundle.entry);
    if (composition === undefined || !composition.subject?.reference) {
      throw Error;
    }
    document = {
      ...document,
      bundleUrl: bundle.id ? `${process.env.REACT_APP_FHIR_URL}/Bundle/${bundle.id}` : "",
      type:
        composition.type.coding && composition.type.coding[0].display
          ? composition.type.coding[0].display
          : "undefined",
    };
    let subject = getSubject(bundle);
    document.subject = subject?.name && subject?.name[0].given && subject?.name[0].given[0]
      ? subject?.name[0].given[0] + " " + subject?.name[0].family
      : "";
    document.sections = await getSections(bundle.entry, composition);
  }
  return document;
}

/**
 * Get the composition from the bundle
 * 
 * @param entries 
 * 
 */
function getComposition(entries: BundleEntry<FhirResource>[]): Composition | undefined {
  let composition;
  for (const entry of entries) {
    if (entry.resource?.resourceType === "Composition") {
      composition = entry.resource;
    }
  }
  return composition;
}

/**
 * Get the subject from the bundle
 * 
 * @param bundle 
 *  
 */
function getSubject(bundle: Bundle): Patient | undefined {
  let subject;
  for (const entry of bundle.entry ?? []) {
    if (entry.resource?.resourceType === "Patient") {
      subject = entry.resource;
    }
  }
  return subject;
}

/**
 * Get the sections from the bundle
 * 
 * @param entries 
 * @param composition 
 * 
 */
async function getSections(entries: BundleEntry<FhirResource>[], composition: Composition): Promise<Section[]> {
  let sections = new Array<Section>();
  const promises = composition.section?.map(async (compositionSection: any) => {
    const section = await getSection(entries, compositionSection);
    if (section.resources.length > 0) {
      sections.push(section);
    }
  }) ?? [];
  await Promise.all(promises);
  return sections;
}

/**
 * Get the section from the bundle
 * 
 * @param entries 
 * @param compositionSection 
 * 
 */
async function getSection(entries: BundleEntry<FhirResource>[], compositionSection: any): Promise<Section> {
  let section: Section = {
    code: compositionSection.code?.coding[0].code,
    display: compositionSection.code?.coding[0].display,
    resources: new Array<any>(),
  };
  const promises = compositionSection.entry?.map(async (compositionEntry: any) => {
    const promises = entries.map(async (entry) => {
      if (entry.fullUrl?.includes(compositionEntry.reference)) {
        try {
          await translateResourceCode(entry.resource);
        } catch (e) {
          console.log(e)
        }
        section.resources.push({
          id: entry.resource?.id,
          type: entry.resource?.resourceType,
          resource: entry.resource,
        });
      }
    }) ?? [];
    await Promise.all(promises);
  }) ?? [];
  await Promise.all(promises);
  return section;
}

/**
 * Translate the resource code
 * 
 * @param resource 
 * 
 */
async function translateResourceCode(resource: any) {
  //TODO See to not have the version hard coded
  if (resource.code && resource.code.coding) {
    const coding = await terminologyService.translateDisplay(resource.code.coding[0], "http://snomed.info/sct/11000315107/version/20240601", i18n.language);
    resource.code.coding[0] = coding;
  }
}

/**
 * DocumentService
 * 
 */
const DocumentService = {
  generateDoc,
  getComposition
};

export default DocumentService;