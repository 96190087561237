import { FunctionComponent } from "react";
import PapyrusPage from "../../components/PapyrusPage/PapyrusPage";
import styles from "./InProgress.module.css";
import { Title } from "@fyrstain/fhir-front-library";
import i18n from "i18next";

const InProgress: FunctionComponent = () => {

    return <PapyrusPage loading={false} needsLogin={false} >
        <div className={`d-flex align-items-center justify-content-center ${styles.mainErrorContainer}`}>
            <img
            className={styles.imageIcon}
            src="/assets/InProgress.png"
            alt="Oops !"
            />
            <Title level={1} prefix={i18n.t('title.inprogress.wip')} content={i18n.t('title.inprogress.comingsoon')} />
        </div>
    </PapyrusPage>
};

export default InProgress;