// FHIR
import { FhirStatus, StatusTag, Title } from "@fyrstain/fhir-front-library";
// React
import {
  FunctionComponent
} from "react";
//components
import { Card } from "react-bootstrap";
// Services
import { Document } from "../../services/DocumentService";
// Styles
import styles from "./BundleDocumentViewer.module.css";
// QR Code
import { QRCode } from "react-qrcode";
// Translation
import i18n from "i18next";

const BundleDocumentViewer: FunctionComponent<{ document: Document }> = (props) => {

  const document = props.document;

  /////////////////////////////////////
  //             Content             //
  /////////////////////////////////////

  return (
    <>
      <Card>
        <Card.Header>
          <Title
            level={2}
            prefix={i18n.t("title.documentype") + " : "}
            content={document?.type === "http://loinc.org/60591-5" ? "International Patient summary" : document?.type}
          />
          <Title
            level={2}
            prefix={i18n.t("title.documentsubject") + " : "}
            content={document?.subject}
          />
        </Card.Header>
        {document?.sections?.map((section) => (
          <Card className={styles.containerSection}>
            <Card.Header className={styles.headerCard}>
              <Title level={3} prefix={section?.display} />
            </Card.Header>
            <div className={["flexWrap", styles.cards].join(' ')}>
              {section.resources.map((resource) => (
                <Card className={styles.cardColor}>
                  {
                    {
                      MedicationStatement: (
                        <Card.Body>
                          <Card.Text>
                            <div className={styles.statusTag}>
                              <b>{i18n.t("label.status") + " : "}</b>
                              {resource.resource.status === "active" ||
                              resource.resource.status === "completed" ? (
                                <StatusTag
                                  status={FhirStatus.error}
                                  statusMessage={resource.resource.status}
                                />
                              ) : (
                                <StatusTag
                                  status={FhirStatus.successful}
                                  statusMessage={resource.resource.status}
                                />
                              )}
                            </div>
                          </Card.Text>
                          <Card.Text className={styles.medicationLineBreak}>
                            <b>{i18n.t("label.medication") + " : "}</b>
                            {resource.resource.medicationReference?.reference}
                            {resource.resource.medicationCodeableConcept?.coding[0].display}
                          </Card.Text>
                        </Card.Body>
                      ),
                      Condition: (
                        <Card.Body>
                          <Card.Text>
                            <div className={styles.statusTag}>
                              <b>{i18n.t("label.clinicalstatus") + " : "}</b>
                              {resource.resource.clinicalStatus?.coding[0]
                                .code === "active" ||
                              resource.resource.clinicalStatus?.coding[0]
                                .code === "recurrence" ||
                              resource.resource.clinicalStatus?.coding[0]
                                .code === "relapse" ? (
                                <StatusTag
                                  status={FhirStatus.error}
                                  statusMessage={
                                    resource.resource.clinicalStatus?.coding[0]
                                      .code
                                  }
                                />
                              ) : (
                                <StatusTag
                                  status={FhirStatus.successful}
                                  statusMessage={
                                    resource.resource.clinicalStatus?.coding[0]
                                      .code
                                  }
                                />
                              )}
                            </div>
                          </Card.Text>
                          <Card.Text className={styles.labelText}>
                            <b>{i18n.t("label.pathology") + " : "}</b>
                            {resource.resource.code?.coding[0].display ??
                              resource.resource.code?.coding[0].code}
                          </Card.Text>
                        </Card.Body>
                      ),
                      Procedure: (
                        <Card.Body>
                          <Card.Text>
                            <b>Type : </b>
                            {resource.resource.code?.coding[0].display ??
                              resource.resource.code?.coding[0].code}
                          </Card.Text>
                        </Card.Body>
                      ),
                      Observation: (
                        <Card.Body>
                          <Card.Text>
                            <p>
                            <b>Type : </b>
                            {resource.resource.code?.coding[0].display ??
                              resource.resource.code?.coding[0].code}
                            </p>
                            <p>
                            <b>Value : </b>
                            {resource.resource.valueCodeableConcept?.coding[0].display ??
                              resource.resource.valueCodeableConcept?.coding[0].code}
                            </p>
                          </Card.Text>
                        </Card.Body>
                      ),
                      DiagnosticReport: (
                        <Card.Body>
                          <Card.Text>{resource.type}</Card.Text>
                        </Card.Body>
                      ),
                      Specimen: (
                        <Card.Body>
                          <Card.Text>{resource.type}</Card.Text>
                        </Card.Body>
                      ),
                      Immunization: (
                        <Card.Body>
                          <Card.Text>
                            <div className={styles.statusTag}>
                              <b>{i18n.t("label.status") + " : "}</b>
                              {resource.resource.status === "completed" ? (
                                  <StatusTag
                                  status={FhirStatus.successful}
                                  statusMessage={resource.resource.status}
                                  />
                                ) : (
                                  <StatusTag
                                    status={FhirStatus.error}
                                    statusMessage={resource.resource.status}
                                  />
                              )}
                            </div>
                          </Card.Text>
                          <label className={styles.labelText}>
                            <b>Type : </b>
                            {resource.resource.vaccineCode?.coding[0].display ??
                              resource.resource.vaccineCode?.coding[0].code}
                          </label>
                        </Card.Body>
                      ),
                      AllergyIntolerance: (
                        <Card.Body>
                          <Card.Text className={styles.labelText}>
                            <b>Type : </b>
                            {resource.resource.code?.coding[0].code}-
                            {resource.resource.code?.coding[0].display}
                          </Card.Text>
                        </Card.Body>
                      ),
                    }[String(resource.type)]
                  }
                </Card>
              ))}
            </div>
          </Card>
        ))}
      </Card>
      <div>
        <QRCode value={document.bundleUrl ?? ''} />
      </div>
    </>
  );
};

export default BundleDocumentViewer;
