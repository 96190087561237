// FHIR
import Client from "fhir-kit-client";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
// Navigation
import { useNavigate } from "react-router-dom";
// Components
import { SearchableTable, ValueSetLoader } from "@fyrstain/fhir-front-library";
import PapyrusPage from "../../components/PapyrusPage/PapyrusPage";
// Font Awesome
import { faEye } from "@fortawesome/free-solid-svg-icons";
// Day js   
import dayjs from "dayjs";

const BundleDocuments: FunctionComponent = () => {

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////

    const bundleDocumentCodes = ['60591-5', '11502-2'];
    const [refreshKey] = useState(0);

    /////////////////////////////////////
    //             Client              //
    /////////////////////////////////////

    const fhirClient = useMemo(() => new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    }), []);

    /////////////////////////////////////
    //             Loader              //
    /////////////////////////////////////

    const valueSetLoader = useMemo(() => new ValueSetLoader(fhirClient), [fhirClient]);

    /////////////////////////////////////
    //           Navigation            //
    /////////////////////////////////////

    const navigate = useNavigate();

    const onDetails = useCallback((id: string) => {
        navigate("/BundleDocumentViewer/" + id);
    }, [navigate]);

    /////////////////////////////////////
    //             Errors              //
    /////////////////////////////////////

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /////////////////////////////////////
    //          Page Loading           //
    /////////////////////////////////////

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        /**
         * Load the initial state of the page.
         */
        async function loadPage() {
            setLoading(false);
        }
        loadPage();
    }, [valueSetLoader]);

    /**
      * Extract the patient's name from the Bundle resource.
      * @param resource The Bundle resource
      * @returns The patient's full name
      */
    const getPatientName = (resource: any): string => {
        const patientResource = resource.entry.find((entry: any) => entry.resource.resourceType === 'Patient')?.resource;
        const givenName = patientResource?.name[0]?.given.join(' ') ?? '';
        const familyName = patientResource?.name[0]?.family ?? '';
        return `${givenName} ${familyName}`;
    };

    /////////////////////////////////////
    //             Content             //
    /////////////////////////////////////

    /**
     * Get the option element to represent the status in an Input Select.
     * @param status the status.
     * @returns the option element.
     */
    function getStatusOption(status: string) {
        return { value: status, label: status };
    }

    return (
        <PapyrusPage titleKey='title.bundledocument' loading={loading} fitFooter={false} needsLogin={false}>
            <>
                <SearchableTable
                    key={refreshKey}
                    searchCriteriaProps={{
                        title: i18n.t('title.searchcriteria'),
                        primaryButtonLabel: i18n.t('button.search'),
                        secondaryButtonLabel: i18n.t('button.reset'),
                        language: i18n.t,
                        searchResultModifier:
                        {
                            _sort: "-_lastUpdated",
                            type: "document",
                        },
                        criteria: [
                            {
                                inputProps: {
                                    label: 'Patient',
                                    type: "text",
                                },
                                // Wait for the orchestrator to restart so that the SP can be used
                                searchParamsName: "patient-name:contains",
                            },
                            {
                                inputProps: {
                                    label: 'Date',
                                    type: "date",
                                },
                                searchParamsName: "date:contains",
                            },
                            {
                                selectProps: {
                                    value: "null",
                                    defaultValue: i18n.t('defaultselectoption.status'),
                                    options: bundleDocumentCodes.map(getStatusOption),
                                },
                                searchParamsName: "composition.type",
                            },
                        ],
                    }}
                    paginationTableProps={{
                        columns: [
                            {
                                header: 'Patient',
                                dataField: "patient",
                                width: "30%",
                            },
                            {
                                header: 'Date',
                                dataField: "date",
                                width: "30%",
                                formatter: (cell: string) => {
                                    return <>{dayjs(cell).fromNow()}</>;
                                },
                            },
                            {
                                header: i18n.t('label.status'),
                                dataField: "status",
                                width: "12%",
                                tabletWidth: "20%",
                            },
                        ],
                        action: [
                            {
                                icon: faEye,
                                onClick: onDetails,
                            },
                        ],
                        mapResourceToData: async (resource: any) => {
                            return {
                                id: resource.id,
                                patient: getPatientName(resource),
                                date: resource.entry[0]?.resource?.meta?.lastUpdated,
                                status: resource.entry[0].resource.type.coding[0].code,
                            };
                        },
                        searchProps: {
                            serverUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir',
                            resourceType: 'Bundle',
                        },
                        onError: onError,
                    }}
                />
            </>
        </PapyrusPage>
    );
};

export default BundleDocuments;