// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BundleDocumentViewer_cardColor__Ix-z- {
    background: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
    border-radius: 0.5rem !important;
    color: var(--white) !important;
    width: 17rem !important;
    height: 12rem !important;
    max-height: 100% !important;
    margin: 0.5rem;
}

.BundleDocumentViewer_containerSection__pEUFR {
    margin: 0.8rem;
}

.BundleDocumentViewer_cards__0k3-9 {
    margin: 0 1rem;
}

.BundleDocumentViewer_labelText__TOx9F {
    line-break: normal;
    text-align: start;
}

.BundleDocumentViewer_medicationLineBreak__539n- {
    line-break: anywhere;
}

.BundleDocumentViewer_headerCard__uG-2- {
    padding: 1.4rem !important;
}

.BundleDocumentViewer_statusTag__qT7gX {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4rem;
}`, "",{"version":3,"sources":["webpack://./src/components/BundleViewer/BundleDocumentViewer.module.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;IAC7C,+CAA+C;IAC/C,gCAAgC;IAChC,8BAA8B;IAC9B,uBAAuB;IACvB,wBAAwB;IACxB,2BAA2B;IAC3B,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".cardColor {\r\n    background: var(--secondary-color) !important;\r\n    border-color: var(--secondary-color) !important;\r\n    border-radius: 0.5rem !important;\r\n    color: var(--white) !important;\r\n    width: 17rem !important;\r\n    height: 12rem !important;\r\n    max-height: 100% !important;\r\n    margin: 0.5rem;\r\n}\r\n\r\n.containerSection {\r\n    margin: 0.8rem;\r\n}\r\n\r\n.cards {\r\n    margin: 0 1rem;\r\n}\r\n\r\n.labelText {\r\n    line-break: normal;\r\n    text-align: start;\r\n}\r\n\r\n.medicationLineBreak {\r\n    line-break: anywhere;\r\n}\r\n\r\n.headerCard {\r\n    padding: 1.4rem !important;\r\n}\r\n\r\n.statusTag {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    gap: 0.4rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardColor": `BundleDocumentViewer_cardColor__Ix-z-`,
	"containerSection": `BundleDocumentViewer_containerSection__pEUFR`,
	"cards": `BundleDocumentViewer_cards__0k3-9`,
	"labelText": `BundleDocumentViewer_labelText__TOx9F`,
	"medicationLineBreak": `BundleDocumentViewer_medicationLineBreak__539n-`,
	"headerCard": `BundleDocumentViewer_headerCard__uG-2-`,
	"statusTag": `BundleDocumentViewer_statusTag__qT7gX`
};
export default ___CSS_LOADER_EXPORT___;
