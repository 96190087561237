// FHIR
import { FhirStatus, StatusTag, Title } from "@fyrstain/fhir-front-library";
// React
import { FunctionComponent } from "react";
//components
import { Card, Accordion, Form } from "react-bootstrap";
// Services
import { Document } from "../../services/DocumentService";
// Styles
import styles from "./BundleDocumentViewer.module.css";
// QR Code
import { QRCode } from "react-qrcode";
// Translation
import i18n from "i18next";

const BundleDocumentViewer: FunctionComponent<{ document: Document }> = (
  props
) => {
  const document = props.document;

  /////////////////////////////////////
  //             Content             //
  /////////////////////////////////////

  // Component to display the label value details
  const LabelValueDetail: FunctionComponent<{ label: string, value: string }> = ({ label, value }) => (
    <div className="d-flex gap-2">
      <Form.Label className="fw-semibold">{label}:</Form.Label>
      <Form.Text>{value}</Form.Text>
    </div>
  );

  return (
    <>
      <div className="d-flex flex-column gap-4">
        <Card>
          <Card.Header>
            <Title level={2} content="Informations" />
          </Card.Header>
          <Card.Body className="d-flex flex-column gap-0.5">
            <Title
              level={3}
              prefix={i18n.t("title.documentype") + ": "}
              content={document?.type}
            />
            <Title
              level={3}
              prefix={i18n.t("title.documentsubject") + ": "}
              content={document?.subject}
            />
          </Card.Body>
        </Card>

        <div className="d-flex flex-column gap-3">
          {document?.sections?.map((section) => (
            <Accordion defaultActiveKey="none">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <Title level={2} content={section?.display} />
                </Accordion.Header>
                <Accordion.Body className={styles.scrollable}>
                  <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-3">
                    {section.resources.map((resource) => (
                      <div className="col">
                        <Card className={styles.card}>
                          {
                            {
                              MedicationStatement: (
                                <Card.Body>
                                  <Card.Text>
                                    <div className="d-flex align-items-center gap-2 fw-semibold">
                                      {i18n.t("label.status")}:
                                      {resource.resource.status === "active" ||
                                        resource.resource.status === "completed" ? (
                                        <StatusTag
                                          status={FhirStatus.error}
                                          statusMessage={resource.resource.status}
                                        />
                                      ) : (
                                        <StatusTag
                                          status={FhirStatus.successful}
                                          statusMessage={resource.resource.status}
                                        />
                                      )}
                                    </div>
                                  </Card.Text>
                                  <LabelValueDetail
                                    label={i18n.t("label.medication")}
                                    value={resource.resource.medicationCodeableConcept?.coding[0].display ?? 'N/A'}
                                  />
                                </Card.Body>
                              ),
                              Condition: (
                                <Card.Body>
                                  <Card.Text>
                                    <div className="d-flex align-items-center gap-2 fw-semibold">
                                      {i18n.t("label.clinicalstatus")}:
                                      {resource.resource.clinicalStatus?.coding[0]
                                        .code === "active" ||
                                        resource.resource.clinicalStatus?.coding[0]
                                          .code === "recurrence" ||
                                        resource.resource.clinicalStatus?.coding[0]
                                          .code === "relapse" ? (
                                        <StatusTag
                                          status={FhirStatus.error}
                                          statusMessage={
                                            resource.resource.clinicalStatus?.coding[0]
                                              .code
                                          }
                                        />
                                      ) : (
                                        <StatusTag
                                          status={FhirStatus.successful}
                                          statusMessage={
                                            resource.resource.clinicalStatus?.coding[0]
                                              .code
                                          }
                                        />
                                      )}
                                    </div>
                                  </Card.Text>
                                  <LabelValueDetail
                                    label={i18n.t("label.pathology")}
                                    value={resource.resource.code?.coding[0].display ??
                                      resource.resource.code?.coding[0].code}
                                  />
                                </Card.Body>
                              ),
                              Procedure: (
                                <Card.Body>
                                  <LabelValueDetail
                                    label="Type"
                                    value={resource.resource.code?.coding[0].display ??
                                      resource.resource.code?.coding[0].code}
                                  />
                                </Card.Body>
                              ),
                              Observation: (
                                <Card.Body>
                                  <LabelValueDetail
                                    label="Type"
                                    value={resource.resource.code?.coding[0].display ??
                                      resource.resource.code?.coding[0].code}
                                  />
                                </Card.Body>
                              ),
                              DiagnosticReport: (
                                <Card.Body>
                                  <Card.Text>{resource.type}</Card.Text>
                                </Card.Body>
                              ),
                              Specimen: (
                                <Card.Body>
                                  <Card.Text>{resource.type}</Card.Text>
                                </Card.Body>
                              ),
                              Immunization: (
                                <Card.Body>
                                  <Card.Text>
                                    <div className="d-flex align-items-center gap-2 fw-semibold">
                                      {i18n.t("label.status")}:
                                      {resource.resource.status === "completed" ? (
                                        <StatusTag
                                          status={FhirStatus.successful}
                                          statusMessage={resource.resource.status}
                                        />
                                      ) : (
                                        <StatusTag
                                          status={FhirStatus.error}
                                          statusMessage={resource.resource.status}
                                        />
                                      )}
                                    </div>
                                  </Card.Text>
                                  <LabelValueDetail
                                    label="Type"
                                    value={resource.resource.vaccineCode?.coding[0].display ??
                                      resource.resource.vaccineCode?.coding[0].code}
                                  />
                                </Card.Body>
                              ),
                              AllergyIntolerance: (
                                <Card.Body>
                                <LabelValueDetail
                                  label="Type"
                                  value={`${resource.resource.code?.coding[0].code} - ${resource.resource.code?.coding[0].display}`}
                                />
                                </Card.Body>
                              ),
                            }[String(resource.type)]
                          }
                        </Card>
                      </div>
                    ))}
                  </div>
                </Accordion.Body >
              </Accordion.Item>
            </Accordion>
          ))}
        </div>
      </div>

      <div>
        <QRCode value={document.bundleUrl ?? ''} />
      </div>
    </>
  );
};

export default BundleDocumentViewer;