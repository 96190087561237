// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentImport_riskButton__AgFoF {
    margin-bottom: 2rem;
    margin-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/DocumentImport/DocumentImport.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".riskButton {\r\n    margin-bottom: 2rem;\r\n    margin-right: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"riskButton": `DocumentImport_riskButton__AgFoF`
};
export default ___CSS_LOADER_EXPORT___;
