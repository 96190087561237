// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BundleDocumentViewer_card__cdmCJ {
    background-color: var(--gray) !important;
    border-radius: 1rem !important;
    color: var(--secondary-color) !important;
    border: outset !important;
    height: 10rem !important;
}

.BundleDocumentViewer_scrollable__Eiq6k {
    max-height: 44rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.BundleDocumentViewer_scrollable__Eiq6k::-webkit-scrollbar {
    width: 0.4rem;
    background: var(--lightGray);
}

.BundleDocumentViewer_scrollable__Eiq6k::-webkit-scrollbar-track {
    border-radius: 0.5rem;
}

.BundleDocumentViewer_scrollable__Eiq6k::-webkit-scrollbar-thumb {
    background: var(--gray);
}

.BundleDocumentViewer_scrollable__Eiq6k::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/components/BundleViewer/BundleDocumentViewer.module.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,8BAA8B;IAC9B,wCAAwC;IACxC,yBAAyB;IACzB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".card {\r\n    background-color: var(--gray) !important;\r\n    border-radius: 1rem !important;\r\n    color: var(--secondary-color) !important;\r\n    border: outset !important;\r\n    height: 10rem !important;\r\n}\r\n\r\n.scrollable {\r\n    max-height: 44rem;\r\n    overflow-y: auto;\r\n    overflow-x: hidden;\r\n}\r\n\r\n.scrollable::-webkit-scrollbar {\r\n    width: 0.4rem;\r\n    background: var(--lightGray);\r\n}\r\n\r\n.scrollable::-webkit-scrollbar-track {\r\n    border-radius: 0.5rem;\r\n}\r\n\r\n.scrollable::-webkit-scrollbar-thumb {\r\n    background: var(--gray);\r\n}\r\n\r\n.scrollable::-webkit-scrollbar-thumb:hover {\r\n    background: var(--secondary-color);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `BundleDocumentViewer_card__cdmCJ`,
	"scrollable": `BundleDocumentViewer_scrollable__Eiq6k`
};
export default ___CSS_LOADER_EXPORT___;
